import revive_payload_client_0YflWsLVzV from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_qfb6tsjq3s7xxsfd5lysrjtulm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_r24AiHaNy6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_qfb6tsjq3s7xxsfd5lysrjtulm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cYHTSlU2a4 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_qfb6tsjq3s7xxsfd5lysrjtulm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1DkL8mODem from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.26.0_vite@5.4.11_@types+node@18.19.64_sass@1._sranjdw7h75mfo3owao6ddsw2q/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kmEtxk1xg6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_qfb6tsjq3s7xxsfd5lysrjtulm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_RI5s3whepe from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_qfb6tsjq3s7xxsfd5lysrjtulm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yGQWmlZ3pF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_qfb6tsjq3s7xxsfd5lysrjtulm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5UN5TVAwCc from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_qfb6tsjq3s7xxsfd5lysrjtulm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_lq3MPSVW6c from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_qfb6tsjq3s7xxsfd5lysrjtulm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_wlrb1b3aFQ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.mjs";
import plugin_AO7jQ56fIl from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/prismic/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/opt/build/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_LwIuqxzbNg from "/opt/build/repo/node_modules/.pnpm/nuxt-primevue@0.3.1_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import analytics_client_mtqVw3YRXk from "/opt/build/repo/plugins/analytics.client.ts";
import emitter_eaMgM0oZTb from "/opt/build/repo/plugins/emitter.ts";
import scroll_client_HJUo2UKx9n from "/opt/build/repo/plugins/scroll.client.ts";
export default [
  revive_payload_client_0YflWsLVzV,
  unhead_r24AiHaNy6,
  router_cYHTSlU2a4,
  _0_siteConfig_1DkL8mODem,
  payload_client_kmEtxk1xg6,
  navigation_repaint_client_RI5s3whepe,
  check_outdated_build_client_yGQWmlZ3pF,
  chunk_reload_client_5UN5TVAwCc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lq3MPSVW6c,
  plugin_client_wlrb1b3aFQ,
  plugin_AO7jQ56fIl,
  primevue_plugin_egKpok8Auk,
  plugin_client_LwIuqxzbNg,
  analytics_client_mtqVw3YRXk,
  emitter_eaMgM0oZTb,
  scroll_client_HJUo2UKx9n
]